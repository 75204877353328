<template>
  <section v-if="data" class="o-requisites-default">
    <div class="o-requisites-default__container container">
      <div class="o-requisites-default__communication">
        <div class="o-requisites-default__content">
          <h2 class="o-requisites-default__title">
            {{ data.communication.title }}
          </h2>

          <div class="o-requisites-default__list">
            <div v-for="list in data.communication.items" class="o-requisites-default__list-item">
              <span v-if="list.title">{{ list.title }}</span>

              <span v-if="list.type === 'plain'">
                {{ list.value }}
              </span>

              <a
                v-else-if="list.type === 'phone'"
                :href="`tel:${usePhone(list.value as IComponentPhoneField).htmlPhone.value}`"
              >
                {{ usePhone(list.value as IComponentPhoneField).normalizedPhone.value }}
              </a>

              <a
                v-else-if="list.type === 'email'"
                :href="`mailto:${list.value}`"
                class="o-requisites-default__list-item"
              >
                {{ list.value }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="o-requisites-default__requisites">
        <div class="o-requisites-default__content o-requisites-default__content-wrapper">
          <h2 class="o-requisites-default__title">
            {{ data.details.title }}
          </h2>

          <div class="o-requisites-default__list">
            <div v-for="list in data.details.items" class="o-requisites-default__list-item">
              <span v-if="list.title">{{ list.title }}</span>

              <span v-if="list.type === 'plain'">
                {{ list.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';

// Composables
import usePhone from '~/composables/api/usePhone';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentPhoneField, IComponentProps } from '~/typings/types/component.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import type { TORequisitesDefault } from '~/components/O/Requisites/Default/ORequisitesDefault.types';

// Schema
import schema from '~/components/O/Requisites/Default/ORequisitesDefault.schema';

defineOptions({
  name: 'ORequisitesDefault',
});

const props = withDefaults(defineProps<IComponentProps<TORequisitesDefault>>(), {
  viewName: 'ORequisitesDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const data = props.data.component;

useComponentSchemaValidator(schema, props);
</script>
